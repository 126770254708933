<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Cabang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="branch_name"
                placeholder="Masukkan cabang"
                :class="{'uk-form-danger': errors.has('branch_name')}"
              >
              <div
                v-show="errors.has('branch_name')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('branch_name') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Title Jabatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.head_title"
                v-validate="{ required: true }"
                class="uk-input"
                type="text"
                name="head_title"
                placeholder="Masukkan title"
                :class="{'uk-form-danger': errors.has('head_title')}"
              >
              <div
                v-show="errors.has('head_title')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('head_title') }}
              </div>
            </div>
          </div>
          <div
            v-if="formBranch.head_title"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama {{ formBranch.head_title }}
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.branch_head_name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="branch_head"
                :placeholder="`Masukkan nama ${formBranch.head_title}...`"
                :class="{'uk-form-danger': errors.has('branch_head')}"
              >
              <div
                v-show="errors.has('branch_head')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('branch_head') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            v-if="formBranch.staff_title"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama {{ formBranch.staff_title }}
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.data_analyst_name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="data_analyst"
                :placeholder="`Masukkan nama ${formBranch.staff_title}...`"
                :class="{'uk-form-danger': errors.has('data_analyst')}"
              >
              <div
                v-show="errors.has('data_analyst')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('data_analyst') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Title Jabatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.staff_title"
                v-validate="{ required: true }"
                class="uk-input"
                type="text"
                name="staff_title"
                placeholder="Masukkan title"
                :class="{'uk-form-danger': errors.has('staff_title')}"
              >
              <div
                v-show="errors.has('staff_title')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('staff_title') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="formBranch.status"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="formBranch.status"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div
            class="uk-grid-small uk-child-width-1-3@xl uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s  uk-child-width-1-1 uk-flex-right"
            uk-grid
          >
            <div class="uk-margin-bottom">
              <div class="upload-card">
                <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium">
                  {{ formBranch.head_title || '...' }}
                </div>
                <div
                  class="uk-width-1-1 uk-margin-small-bottom js-upload"
                  uk-form-custom
                >
                  <input
                    ref="file_cabang"
                    class="uk-input"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    @change="handleUploadHeadSign"
                  >
                  <div
                    class="uk-card uk-card-body uk-width-1-1 uk-flex uk-flex-center uk-flex-middle preview-image uk-padding-small card-upload"
                    tabindex="-1"
                  >
                    <img
                      v-if="previewImageCabang"
                      :src="previewImageCabang.includes('branch/image/') ? `${image_url}${previewImageCabang}` : previewImageCabang"
                      class="preview-image"
                      alt="sign kepcab"
                      uk-img
                    >
                    <template v-else>
                      <img
                        :src="`${images}/icon/logout.svg`"
                        alt="icon upload"
                        class="uk-margin-small-right icon-upload"
                      >
                      <div class="label-upload">
                        Unggah TTD Anda Disini
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-show="errorInputFile!='' && !previewImageCabang"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputFile }}
                </div>
                <div class="uk-width-1-1 uk-text-center uk-margin-small text-medium">
                  {{ formBranch.branch_head_name || '...' }}
                </div>
              </div>
            </div>
            <div class="uk-margin-bottom">
              <div class="upload-card">
                <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium">
                  {{ formBranch.staff_title || '...' }}
                </div>
                <div
                  class="uk-width-1-1 uk-margin-small-bottom js-upload"
                  uk-form-custom
                >
                  <input
                    ref="file_analist"
                    class="uk-input"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    @change="handleUploadAnalystSign"
                  >
                  <div
                    class="uk-card uk-card-body uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-padding-small preview-image card-upload"
                    tabindex="-1"
                  >
                    <img
                      v-if="previewImageAnalist"
                      :src="previewImageAnalist.includes('branch/image/') ? `${image_url}${previewImageAnalist}` : previewImageAnalist"
                      class="preview-image"
                      alt="sign analist"
                      uk-img
                    >
                    <template v-else>
                      <img
                        :src="`${images}/icon/logout.svg`"
                        alt="icon upload"
                        class="uk-margin-small-right icon-upload"
                      >
                      <div class="label-upload">
                        Unggah TTD Anda Disini
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-show="errorInputFile!='' && !previewImageAnalist"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputFile }}
                </div>
                <div class="uk-width-1-1 uk-text-center uk-margin-small text-medium">
                  {{ formBranch.data_analyst_name || '...' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import 'vue2-datepicker/index.css'
import { STATUS } from '@/utils/constant'
import { getBase64 } from '@/utils/formater'
import {
  notificationDanger
} from '@/utils/notification'

export default {
  props: {
    dataBranch: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: false,
      status: STATUS,
      formBranch: {
        status: 'true'
      },
      previewImageAnalist: '',
      previewImageCabang: '',
      errorInputFile:''
    }
  },
  watch: {
    async dataBranch() {
      if (this.dataBranch) {
        this.formBranch = {
          id: this.dataBranch.id || '',
          name: this.dataBranch.name || '-',
          status: this.dataBranch.status ? true : false,
          branch_head_name: this.dataBranch.branch_head_name || '-',
          data_analyst_name: this.dataBranch.data_analyst_name || '-',
          data_analyst_sign: this.dataBranch.data_analyst_sign || '-',
          branch_head_sign: this.dataBranch.branch_head_sign || '-',
          head_title: this.dataBranch.head_title || '-',
          staff_title: this.dataBranch.staff_title || '-'
        }

        this.previewImageAnalist = this.dataBranch.data_analyst_sign || ''
        this.previewImageCabang = this.dataBranch.branch_head_sign || ''
      }
    }
  },
  methods: {
    async onRemoveCity() {
      await this.handleFindCity()
    },
    ...mapMutations({
      setModalEdit: 'branch/SET_MODAL_EDIT'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.formBranch.status = this.formBranch ? Boolean(this.formBranch.status) : ''
      this.checkImage()
      this.$validator.validateAll().then((success) => {
        const formData = new FormData()
        if (success) {
          if (this.$validator.errors.any()) return

          formData.append('id', this.formBranch.id)
          formData.append('name', this.formBranch.name)
          formData.append('branch_head_name', this.formBranch.branch_head_name)
          formData.append('data_analyst_name', this.formBranch.data_analyst_name)
          formData.append('status', this.formBranch.status)
          formData.append('head_title', this.formBranch.head_title)
          formData.append('staff_title', this.formBranch.staff_title)

          if (this.previewImageAnalist.includes('base64')){
            formData.append('data_analyst_sign_file', this.formBranch.data_analyst_sign_file)
          }
          if(this.previewImageCabang.includes('base64')){
            formData.append('branch_head_sign_file', this.formBranch.branch_head_sign_file)
          }

          this.setModalEdit(formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    },
    handleUploadHeadSign(){
      let imgCabang = this.$refs.file_cabang.files[0]
      if (imgCabang.size <= 500000) {
        this.formBranch.branch_head_sign_file = imgCabang
        return getBase64(imgCabang, file => this.previewImageCabang = file )
      } else {
        notificationDanger({ message: 'File tidak boleh lebih dari 500KB' })
      }
    }
    ,
    handleUploadAnalystSign() {
      let imgAnalis = this.$refs.file_analist.files[0]
      if (this.$refs.file_analist.files.length !== 0) {
        if (imgAnalis.size <= 500000) {
          this.formBranch.data_analyst_sign_file = imgAnalis
          return getBase64(imgAnalis, file => this.previewImageAnalist = file )
        } else {
          notificationDanger({ message: 'File tidak boleh lebih dari 500KB' })
        }
      }
    },
    checkImage(){
      if(!this.formBranch.branch_head_sign_file && !this.formBranch.data_analyst_sign_file){
        this.errorInputFile = "Required"
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>

  .label-upload {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #C2D6CE;
  }

  .preview-image {
    width: auto;
    height: 150px;
    object-fit: cover;
  }

  .icon-upload {
    max-width: 25px;
    height: auto;
  }

</style>
